<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" md="8" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Detail Kuesioner</h2>

      <p class="h3">Pertanyaan</p>

      <b-form-textarea
          id="textarea"
          v-model="text"
          placeholder="Masukkan Pertanyaan"
          rows="3"
          max-rows="6"
          class="overflow-hidden mb-3"
      ></b-form-textarea>

      <div class="options">
        <div v-for="(option, index) in options" :key="index" class="mb-3">
          <b-form-input v-model="option.text" size="md" :key="option.id" class="w-50"/>
          <b-button @click="removeOption(index)" variant="black-80">
            <b-icon icon="x"></b-icon>
          </b-button>
        </div>
        <b-button @click="addOption" variant="info">
          <b-icon icon="plus"></b-icon>
        </b-button>
      </div>
      <div class="d-flex justify-content-end">
        <b-button-group>
          <b-button variant="danger" class="mx-3">Batal</b-button>
          <b-button variant="info">Simpan</b-button>
        </b-button-group>
      </div>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormTextarea,
  BFormInput,
  BIcon,
  BButtonGroup
} from "bootstrap-vue";

export default {
  name: "ViewQuisioner",
  data() {
    return {
      options: [{text: "Ya", id: 1}, {text: "Tidak", id: 2}]
    }
  },
  methods: {
    addOption() {
      const newId = this.options.length + 1
      this.options.push({text: "", id: newId});
    },
    removeOption(index) {
      this.options.splice(index, 1)
    }
  },
  components: {
    BCol,
    BRow,
    BButton,
    BFormTextarea,
    BFormInput,
    BIcon,
    BButtonGroup
  }
}
</script>

<style scoped>

</style>